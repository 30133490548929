/* default css start */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
.header-area {
  background: rgb(12, 157, 253);
  background-position: center center;
  background-size: cover;
}
/* default css end */

/* navbar regular css start */
.navbar-area {
  background: rgb(12, 157, 253);
  border-bottom: 1px solid rgb(12, 157, 253);
}
.site-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-navbar a img {
  width: 2rem;
}
a.site-logo {
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-navbar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  z-index: 2;
}
.site-navbar ul li a {
  color: #fff;
  padding: 20px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}
.site-navbar ul li a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.site-navbar ul li a.active {
  background: rgba(255, 255, 255, 0.1);
}
/* navbar regular css end */

/* nav-toggler css start */
.nav-toggler {
  border: 3px solid #fff;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
  height: 39px;
  display: none;
}
.nav-toggler span,
.nav-toggler span:before,
.nav-toggler span:after {
  width: 28px;
  height: 3px;
  background-color: #fff;
  display: block;
  transition: 0.3s;
}
.nav-toggler span:before {
  content: "";
  transform: translateY(-9px);
}
.nav-toggler span:after {
  content: "";
  transform: translateY(6px);
}
.nav-toggler.toggler-open span {
  background-color: transparent;
}
.nav-toggler.toggler-open span:before {
  transform: translateY(0px) rotate(45deg);
}
.nav-toggler.toggler-open span:after {
  transform: translateY(-3px) rotate(-45deg);
}
/* nav-toggler css start */

/* intro-area css start */
.intro-area {
  height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
}
.intro-area h2 {
  font-size: 50px;
  font-weight: 300;
  line-height: 50px;
  margin-bottom: 25px;
}
.intro-area p {
  font-size: 18px;
}
/* intro-area css end */

/* mobile breakpoint start */
@media screen and (max-width: 767px) {
  .container {
    max-width: 720px;
  }
  /* navbar css for mobile start */
  .nav-toggler {
    display: block;
  }
  .site-navbar {
    min-height: 60px;
  }
  .site-navbar ul {
    position: absolute;
    width: 100%;
    height: calc(100vh - 60px);
    left: 0;
    top: 60px;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #444;
    background-color: rgba(0, 0, 0, 0.9);
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
  }
  .site-navbar ul li {
    width: 100%;
    text-align: center;
  }
  .site-navbar ul li a {
    padding: 25px;
  }
  .site-navbar ul li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .site-navbar ul.open {
    max-height: 100vh;
    overflow: visible;
  }
  .intro-area h2 {
    font-size: 36px;
    margin-bottom: 15px;
  }
  /* navbar css for mobile end */
}
/* mobile breakpoint end */
